import { createContext, useState, useEffect, ReactNode } from "react";
import { GenericObject, Loading } from "components";
import { useFirebaseContext } from 'hooks';
import { CollectionReference, DocumentReference, collection, doc } from 'firebase/firestore';

export interface Refs {
  cartsRef: CollectionReference;
  cartRef: (id: string) => DocumentReference;
  customersRef: CollectionReference;
  customerRef: (id: string) => DocumentReference;
}

export const RefsContext = createContext<Partial<Refs>>({} as GenericObject);
export const RefsConsumer = RefsContext.Consumer;

export const RefsProvider = ({ children }:{ children: ReactNode }) => {

  const [refs, setRefs] = useState<GenericObject>();

  const {db} = useFirebaseContext();

  useEffect(() => {
    if (db) {
        const cartsRef = collection(db, 'carts');
        const cartRef = (id: string) => doc(cartsRef, id);
        const customersRef = collection(db, 'customers');
        const customerRef = (id: string) => doc(customersRef, id);

        setRefs({
            cartsRef,
            cartRef,
            customersRef,
            customerRef,
        });
    }
  },[db]);

  if (!refs || !db) return <Loading/>;

  return (
    <RefsContext.Provider value={{...refs}}>
      {children}
    </RefsContext.Provider>
  );
  
};
