import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { ScrollToTop } from 'components';
import {PathRouteProps} from 'react-router';
import loadable from '@loadable/component';

import Home from './Home';

const About = loadable(() => import('./About'));
const Config = loadable(() => import('./Config'));
const Contact = loadable(() => import('./Contact'));
const AuthRoutes = loadable(() => import('./Auth'));
const TutorialRoutes = loadable(() => import('./Tutorials'));
const DownloadRoutes = loadable(() => import('./Downloads'));
const SupportRoutes = loadable(() => import('./Support'));
const Firmware = loadable(() => import('./Firmware'));
const Convert = loadable(() => import('./Convert'));
const ShopRoutes = loadable(() => import('./Shop'));

export interface PrivateRouteProps extends PathRouteProps {
  redirect?: string;
}

export const ROUTES = {
    home: '/',
    me: '/',
    about: '/about',
    contact: '/contact',
    faq: '/support',
    signup: '/signup',
    firmware: '/firmware',
    convert: '/convert',
    config: '/config',

    // Auth
    auth: '/auth',
    signin: '/auth/signin',
    signout: '/auth/signout',
    reminder: '/auth/reminder',
    verify_method: (method: string) => `/auth/verify/${method}`,
    confirm: '/auth/confirm',
    loginlink: '/auth/loginlink',

    // Tutorials 
    tutorials: '/tutorials',
    latest: '/tutorials/latest',
    app: '/tutorials/app',
    ptt: '/tutorials/ptt',
    profile: '/tutorials/profile',
    serial: '/tutorials/serial',
    glove: '/tutorials/glove',
    glove_connections: '/tutorials/glove/connections',

    // Downloads
    downloads: '/downloads',
    downloads_profiles: '/downloads/profiles',
    downloads_sounds: '/downloads/sounds',
    downloads_cases: '/downloads/cases',

    // Support 
    support: '/support',
    support_quickstart: '/support/quickstart',
    support_troubleshoot: '/support/troubleshoot',

    // Store (for backwards compatibility)
    store: '/store',

    // Shop
    shop: '/shop',
    shop_cart: '/shop/cart',
    shop_checkout: '/shop/checkout',
    shop_checkout_complete: '/shop/checkout/complete',
    shop_order_status: '/shop/order/status',

};

export const InternalRoutes = () => {
  return (
    <Router>
      <ScrollToTop>
        <Routes>
          <Route index path={ROUTES.home} element={<Home/>} />
          <Route path={ROUTES.about} element={<About/>} />
          <Route path={ROUTES.firmware} element={<Firmware/>} />
          <Route path={ROUTES.convert} element={<Convert/>} />
          <Route path={ROUTES.config} element={<Config/>} />
          <Route path={ROUTES.contact} element={<Contact/>} />
        </Routes>
        <AuthRoutes/>
        <TutorialRoutes/>
        <DownloadRoutes/>
        <SupportRoutes/>
        <ShopRoutes/>
      </ScrollToTop>
    </Router>
  )
          
}